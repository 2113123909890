@import "variables/index";
@import "styles/3rdParty/index";
@import "project/index";

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.flex-row {
  display: flex;
}
.hidden-theme-light {
  display: none;
}
.hidden-theme-dark {
  display: block;
}
@include color-mode(dark) {
  .hidden-theme-light {
    display: block;
  }
  .hidden-theme-dark {
    display: none;
  }
}
code {
  padding-top: $spacer*2;
  text-wrap: wrap;
}
.flex-1 {
  flex:1;
}

.activity-log {
  li {
    &::marker {
      content: none;
    }
  }
}

canvas.react-pdf__Page__canvas,  canvas.react-pdf__Thumbnail__page__canvas, .react-pdf__Page__textContent.textLayer {
      width: 100% !important;
      height: auto !important;
}
.modal-open {
  .crisp-client {
    opacity: 0;
    pointer-events: none;
  }
}

.border-transparent {
  border-color: transparent;
}
.border-none {
  border: none;
}
