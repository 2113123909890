//PROJECT STYLES
@import "buttons";
@import "forms";
@import "custom";
@import "badge";
@import "aside";
@import "nav";
@import "chip";
@import "dropdown";
@import "type";
@import "animation";
@import "shadows";
@import "table";
@import "modals";
@import "toast";
@import "tabs";
.body-bg {
  background-color: $body-bg;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
.disabled > * {
  opacity: 0.25;
  cursor: not-allowed !important;
}
.right-0 {
  right: 0;
}
/*! purgecss start ignore */
svg {
  &.text-body {
    path, circle {
      fill: $body-color;
    }
  }
  &.text-white {
    path, circle {
      fill: white;
    }
  }
  &.text-success {
    path, circle {
      fill: $success;
    }
  }
  &.text-danger {
    path, circle {
      fill: $danger;
    }
  }
}
path, circle {
  &.text-body {
      fill: $body-color;
  }
  &.text-success {
      fill: $success;
  }
  &.text-danger {
      fill: $danger;
  }
  &.text-white {
    path, circle {
      fill: white;
    }
  }
}
.minh-100 {
  min-height: 100%;
}
.fixed-full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.post-img-thumbnail {
  height: 80px;
  width: 80px;
  object-fit: cover;
}
.post-img-thumbnail-lg {
  height: 400px;
  width: 400px;
  object-fit: contain;
}
/*! purgecss end ignore */
